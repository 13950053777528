<template>
    <div>
        <app-layout ref="app-layout" id="print-content">
            <template v-slot:header>
                <Header :title="$t('refund_request')"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('refund_request')"
                              :isFilter="false"/>
            </template>
            <div v-if="data!=null">
                <div class="row data-form">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                        <b-form-group :label="$t('student_number')">
                            {{ data.student_number }}
                        </b-form-group>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                        <b-form-group :label="$t('name_surname')">
                            {{ data.student_name + ' ' + data.student_surname }}
                        </b-form-group>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                        <b-form-group :label="$t('program')">
                            {{ this.getLocaleText(data, 'program_name') }}
                        </b-form-group>
                    </div>
                </div>
                <div class="mb-3 d-inline-block"></div>
                <div class="mb-4">
                    <h6 class="text-uppercase mb-0">{{ $t('account_information').toUpper() }}</h6>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('name_surname')">
                                {{ data.account_holder }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('name_surname')">
                                {{ data.account_holder }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('bank_name')">
                                {{ data.bank_name }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('branch_name')">
                                {{ data.branch_name }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('iban')">
                                {{ data.iban }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('currency')">
                                {{ data.currency }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('reason')">
                                {{ this.getLocaleText(data, 'reason_name') }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('date')">
                                {{ data.created_at }}
                            </b-form-group>
                        </div>
                    </div>
                    <ValidationObserver ref="form">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('status')">
                                        <parameter-selectbox code="refund_request_statuses"
                                                             v-model="data.status"
                                                             :validate-error="errors[0]"
                                        ></parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-md-6" v-if="data && data.status=='declined'">
                                <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('reason_for_rejection')">
                                        <b-form-textarea v-model="data.reason_for_rejection"
                                                      v-uppercase
                                                      :state="errors[0] ? false : null">
                                        </b-form-textarea>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 d-flex mt-3">
                                <div class="row">
                                    <div class="col-auto">
                                        <b-button v-if="disableWhenPrint" variant="success" @click="save">{{ $t('save') }}
                                        </b-button>
                                    </div>

                                    <div class="col-auto">
                                        <b-button v-if="disableWhenPrint" variant="primary" @click="printToPage">{{ $t('print') }}
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";

// Service
import RefundRequestService from "../../../services/RefundRequestService";
import {ValidationProvider, ValidationObserver} from "vee-validate"

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        ParameterSelectbox,
        ValidationProvider,
        ValidationObserver
    },
    metaInfo() {
        return {
            title: this.$t('refund_request')
        }
    },
    data() {
        return {
            id: this.$route.params.id,
            data: null,
            disableWhenPrint: true
        }
    },
    created() {
        RefundRequestService.get(this.id).then(response => {
            this.data = response.data.data;
        });
    },
    methods: {
        async printToPage() {
            this.disableWhenPrint = false;

            this.$refs['app-layout'].$el.style.height = '90vh';
            this.$nextTick(async () => {
                window.print()
                this.disableWhenPrint = true
                this.$refs['app-layout'].$el.style.height = 'auto';
            })
        },
        async save() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                this.$swal.fire({
                    text: "Kaydetmek istediğinize emin misiniz?",
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        const formData = {
                            'status': this.data.status,
                            'reason_for_rejection': this.data.reason_for_rejection
                        }
                        RefundRequestService.updateStatus(this.data.id, formData)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                            })
                            .catch(error => {
                                this._showErrors(error);
                            });
                    }
                });
            }
        },
    },
}
</script>


